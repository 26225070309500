<template>
  <div
    ref="container"
    class="picture-cta"
    :class="[
      content?.background_color
        ? `bg-${content.background_color}`
        : 'bg-violet',
      {
        darker: content?.darken_text,
        landscape: content?.mode === 'landscape',
      },
    ]"
  >
    <div class="wrapper">
      <div
        ref="contentEl"
        class="picture-cta__inner pt-xxxl"
        :class="{ 'pb-xxxl': content.mode === 'landscape' }"
      >
        <div class="picture-cta__img">
          <img
            v-if="content.picture"
            ref="img"
            v-src="content.picture"
            :alt="content.picture.src"
          />
        </div>
        <div class="picture-cta__content">
          <h3
            data-animated
            class="picture-cta__content__title mb-m"
            v-html="content.title"
          ></h3>
          <div
            v-if="content.text"
            data-animated
            class="picture-cta__content__text"
            v-html="content.text"
          ></div>

          <Action
            v-if="content.link"
            data-animated
            :content="{
              ...content.link,
              modifiers: ['btn'],
            }"
            class="picture-cta__content__link"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Picture, Uri } from '@/types'

export interface RibbonCta {
  darken_text?: boolean
  background_color: string
  mode: string
  picture: Picture
  title: string
  text: string
  link?: Uri
}
</script>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import { useScroll } from '@/utils/aware'
import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'

const container = ref<HTMLElement | null>(null)
const contentEl = ref<HTMLElement | null>(null)
const img = ref<HTMLElement | null>(null)
const tl = gsap.timeline({})

defineProps({
  content: {
    type: Object as PropType<RibbonCta>,
    required: true,
  },
})

const appear = (contentChildrenEl: Object) => {
  tl.to(
    contentChildrenEl,
    {
      ease: '8020',
      y: 0,
      opacity: 1,
      duration: 0.5,
      stagger: 0.1,
    },
    0
  )
}

onMounted(() => {
  if (!contentEl.value) {
    return
  }

  const contentChildrenEl = contentEl.value.querySelectorAll('[data-animated]')

  tl.set(contentChildrenEl, {
    y: 20,
    opacity: 0,
  })

  onAppear(
    container.value,
    () => {
      appear(contentChildrenEl)
    },
    {
      once: true,
      rootMargin: '0px 0px -20% 0px',
    }
  )

  useScroll({
    callback: () => {
      if (!img.value) {
        return
      }
      const distanceFromTop =
        img.value!.getBoundingClientRect().top + window.pageYOffset
      const newPos = Math.max(0, (window.pageYOffset - distanceFromTop) / 2)

      gsap.to(img.value, {
        y: newPos,
        ease: 'power4.easeOut',
        duration: 0.1,
      })
    },
  })
})
</script>

<style lang="scss" scoped>
.keyfacts-text-host + :host {
  margin-top: 0;
}

.picture-cta {
  overflow: hidden;
  color: var(--c-text);
  background-color: var(--c-accent-10);

  &.darker {
    color: $c-black;
  }
}

.wrapper {
  @include mq(m) {
    position: relative;
  }
}

.picture-cta__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.pb-xxxl {
    @include mq($until: m) {
      padding-bottom: 0;
    }
  }

  @include mq($until: s) {
    gap: 5rem;
  }

  @include mq(s) {
    flex-direction: row;
  }
}

.picture-cta__img {
  position: relative;
  display: block;
  order: 2;
  overflow: hidden;
  width: 100%;
  padding-top: 90%;
  border-top-left-radius: 50rem;
  border-top-right-radius: 50rem;
  background-color: var(--c-accent-20);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top left;

    @include mq(m) {
      position: relative;
      object-position: 0 0;
    }
  }

  .landscape & {
    @include mq(m) {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      width: 40%;
      height: 70%;
      border-top-right-radius: 0;
      border-bottom-left-radius: 50rem;
      transform: translateY(-50%);
    }

    @include mq(xl) {
      width: 50%;
    }
  }

  @include mq(s) {
    width: calc(100% / 3);
    max-height: 40rem;
    padding-top: 20%;
  }

  @include mq(m) {
    bottom: 0;
    width: calc(100% / 3);
    padding-top: 0;
    border-top-left-radius: 50rem;
    border-top-right-radius: 50rem;
    border-bottom-left-radius: 0;
  }
}

.picture-cta__content {
  max-width: 52rem;

  @include mq(m) {
    width: 55.7%;
  }
}

.picture-cta__content__text {
  :deep(p) {
    font-weight: 400;

    + p {
      margin-top: 3rem;
    }
  }
}

.picture-cta__content__link {
  display: block;
  margin-top: 2rem;

  .darker & {
    color: $c-black;
  }

  @include mq(m) {
    margin-top: $s-font-size-m * 2;
  }

  @include mq(xl) {
    margin-top: $s-font-size-xl * 2;
  }
}
</style>
